import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { selectStyleObj } from "./WriteOff";
import {
  getAging,
  useAllocationIssues,
  useCorrectionTypes,
  useUserData,
} from "./hooks";
import { customDateFormat, listToMap } from "./utils";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UpdateGenericWorkflowDialog from "../Workflow/UpdateGenericWorkflowDialog";

const History = ({ history, useIdMap, correctionTypeIdMap, fixedDetails }) => {
  const [
    openUpdateGenericCorrectionTypeWorkflowDialog,
    setOpenUpdateGenericCorrectionTypeWorkflowDialog,
  ] = useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");
  const [currentTxnWorkflowDetails, setCurrentTxnWorkflowDetails] = useState(
    {}
  );

  const handleUpdateGenericWorkflowDialog = () => {
    setOpenUpdateGenericCorrectionTypeWorkflowDialog(false);
  };
  return (
    <div className="cash-allocation-dialogue-container">
      <Grid container>
        {fixedDetails.map(({ label, value }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            key={label}
            paddingLeft={i % 2 ? 2 : 0}
            paddingRight={i % 2 ? 0 : 2}
            marginBottom={2}
          >
            <span style={{ fontWeight: label === "Policy#" ? 800 : 400 }}>
              {label}
            </span>
            <span style={{ fontWeight: "bold" }}>: {value}</span>
          </Grid>
        ))}
      </Grid>
      <TableContainer sx={{ height: 275, overflow: "auto" }}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                sx={{ fontSize: "15px", fontWeight: 800 }}
                className="table-cell"
              >
                Requested On
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Issue Category
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Reason
              </TableCell>

              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Amt
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Ccy
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                WF Status
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{
                  fontSize: "15px",
                  fontWeight: 800,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {history.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell className="table-cell">
                  {customDateFormat(row?.request_date)}
                </TableCell>
                <TableCell className="table-cell">
                  {row?.issue_category}
                </TableCell>
                <TableCell className="table-cell">
                  {row?.refund_reason}
                </TableCell>
                <TableCell className="table-cell">{row?.refund_amt}</TableCell>
                <TableCell className="table-cell">{row?.currency}</TableCell>
                <TableCell className="table-cell">
                  {row?.workflow?.workflow_status}
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {row?.issue_category !== "Premium Payment" && (
                    <IconButton>
                      <SettingsTwoToneIcon
                        onClick={() => {
                          setCurrentWorkflowName(
                            row?.workflow?.workflow_json_data?.workflow_name ??
                              ""
                          );
                          setCurrentTxnWorkflowDetails(row?.workflow ?? {});
                          setOpenUpdateGenericCorrectionTypeWorkflowDialog(
                            true
                          );
                        }}
                        sx={{ fontSize: "25px", color: "#3b3f44" }}
                      />
                    </IconButton>
                  )}
                  <IconButton>
                    <FileDownloadOutlinedIcon
                      sx={{ fontSize: "25px", color: "blue" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openUpdateGenericCorrectionTypeWorkflowDialog && (
        <UpdateGenericWorkflowDialog
          openUpdateGenericWorkflowDialog={
            openUpdateGenericCorrectionTypeWorkflowDialog
          }
          handleUpdateGenericWorkflowDialog={handleUpdateGenericWorkflowDialog}
          workflowName={currentWorkflowName}
          currentWorkflowDetails={currentTxnWorkflowDetails}
        />
      )}
    </div>
  );
};

const CorrectionTypes = ({ allocation, handleClose, transaction }) => {
  const { history, formData, setFormData, submit, isApiInProgress } =
    useAllocationIssues(allocation, transaction);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showHistory, setShowHistory] = useState(true);
  const correctionTypes = useCorrectionTypes();
  const users = useUserData();
  const fixedDetails = useMemo(
    () => [
      {
        label: "System Date",
        value: formData?.issue_date,
      },
      {
        label: "Txn Date",
        value: customDateFormat(allocation?.bank_txn?.Payment_Receive_Date),
      },
      {
        label: "System",
        value: allocation?.allocation_systemid,
      },
      {
        label: "Bank A/c#",
        value:  allocation?.bank_txn?.Receiving_Bank_Account,
      },
      {
        label: "Status",
        value: allocation?.allocation_status,
      },
      {
        label: "Receivable Amt",
        value: allocation?.bank_txn?.Receivable_Amount,
      },
      {
        label: "Accounting Month",
        value: transaction?.Accounting_Month,
      },
      {
        label: "Aging Bucket",
        value: getAging(formData.age_days),
      },
    ],
    [
      allocation?.allocation_status,
      allocation?.allocation_systemid,
      allocation?.bank_reconciliation?.bank_details?.account_number,
      allocation?.bank_txn?.Payment_Receive_Date,
      allocation?.bank_txn?.Receivable_Amount,
      formData.age_days,
      formData?.issue_date,
      transaction?.Accounting_Month,
    ]
  );

  const useIdMap = useMemo(() => listToMap(users, "id", "user_name"), [users]);
  const correctionTypeIdMap = useMemo(
    () => listToMap(correctionTypes, "id", "correction_type"),
    [correctionTypes]
  );

  useEffect(() => {
    setShowHistory(!!history.length);
  }, [history?.length]);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    handleClose("");
  }, [handleClose]);

  const handleSubmitAssing = useCallback(
    async (e) => {
      e.preventDefault();
      await submit();
      setShowHistory(true);
    },
    [submit]
  );

  const handleInputChange = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
      <form onSubmit={handleSubmitAssing}>
        <DialogTitle
          style={{
            background: "#FCFCFC",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Typography sx={{ fontSize: 20 }}> Correction Types</Typography>
          <Typography
            sx={{ fontSize: 15, fontWeight: 750, marginLeft: "30px" }}
          >
            Policy#: {allocation?.policy_id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {showHistory ? (
            <History
              setShowHistory={setShowHistory}
              fixedDetails={fixedDetails}
              history={history}
              useIdMap={useIdMap}
              correctionTypeIdMap={correctionTypeIdMap}
            />
          ) : (
            <>
              <Grid container>
                {fixedDetails.map(({ label, value }, i) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={label}
                    paddingLeft={i % 2 ? 2 : 0}
                    paddingRight={i % 2 ? 0 : 2}
                    marginBottom={2}
                  >
                    <span>{label} </span>
                    <span style={{ fontWeight: "bold" }}>: {value}</span>
                  </Grid>
                ))}
              </Grid>

              <Grid container>
                <Grid item md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Category</p>
                  <Select
                    style={{ ...selectStyleObj }}
                    name="issue_category"
                    onChange={handleInputChange}
                    value={formData.issue_category}
                    required
                  >
                    <MenuItem value="Refund">Refund</MenuItem>
                    <MenuItem value="Internal Transfer">
                      Internal Transfer
                    </MenuItem>
                    <MenuItem value="Broker Invoice">Broker Invoice</MenuItem>
                    <MenuItem value="Premium Payment">Premium Payment</MenuItem>
                    <MenuItem value="Returned Premium">
                      Returned Premium
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item md={6} paddingLeft={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Correction Type
                  </p>

                  <Select
                    style={{ ...selectStyleObj }}
                    name="correction_type"
                    onChange={handleInputChange}
                    value={formData.correction_type}
                    required
                  >
                    {correctionTypes.map((correction) => (
                      <MenuItem key={correction.id} value={correction.id}>
                        {correction.correction_type}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Assignment</p>

                  <Select
                    style={{ ...selectStyleObj }}
                    name="assignment"
                    onChange={handleInputChange}
                    value={formData.assignment}
                    placeholder="Write details here"
                    required
                  >
                    <MenuItem value="Corrections">Correction</MenuItem>
                    <MenuItem value="GXB">GXB</MenuItem>
                    <MenuItem value="XFI">XFI</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={6} paddingLeft={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Assignment Owner
                  </p>

                  <Select
                    style={{ ...selectStyleObj }}
                    name="issue_owner"
                    onChange={handleInputChange}
                    value={formData.issue_owner}
                    placeholder="Write details here"
                    required
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={12}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Comment</p>

                  <TextareaAutosize
                    style={{
                      height: 50,
                      width: "100%",
                    }}
                    name="comments"
                    value={formData.comments}
                    onChange={handleInputChange}
                    placeholder="Write details here"
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "flex-end", padding: "15px 25px" }}
        >
          <Button
            onClick={() => setShowHistory(!showHistory)}
            color={showHistory ? "warning" : "inherit"}
            variant={showHistory ? "contained" : "outlined"}
            sx={{ borderRadius: 100 }}
          >
            {showHistory ? "Add" : "History"}
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="inherit"
            variant="outlined"
            sx={{ borderRadius: 100, border: "1px solid #BCBDBF" }}
          >
            Close
          </Button>
          {!showHistory && (
            <Button
              variant="contained"
              color="warning"
              sx={{ borderRadius: 100 }}
              type="submit"
              disabled={isApiInProgress}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CorrectionTypes;
