const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  FORGETPASSWORD: "/forget-password",
  MUBST: "/Bank-Txns/Bank-Statment-Transactions",
  MUUF: "/Bank-Txns/Upload-files",
  MUSU: "/Bank-Txns/Statement-Upload",
  MUABT: "/Bank-Txns/Allocate-Bank-Transactions",
  MUCASHTRACKER: "/Bank-Txns/Cash-tracker",
  WLAI: "/Bank-Txns/Work-List-Actions",
  MUBR: "/Bank-Txns/Bank-Reconciliation",
  AUM: "/Administration/User-Maintainace",
  ALU: "/Administration/Lock/Unlock",
  AETM: "/Administration/Entity-Table",
  AUMAU: "/Administration/User-Maintainace/Add-User",
  AUMEU: "/Administration/User-Maintainace/Edit-User",
  AUMVU: "/Administration/User-Maintainace/View-User",
  LOBETM: "/Administration/LOB-Table",
  SCMTM: "/Administration/SCM-Table",
  BATM: "/Administration/Binding-Agreement",
  CTTM: "/Administration/CorrectionType-Table",
  ASTM: "/Administration/AllocationStatus-Table",
  BTM: "/Administration/Broker-Table",
  CTM: "/Administration/Corrective-Table",
  PTM: "/Administration/Policy-Table",
  CYTM: "/Administration/Currency-Table",
  BETM: "/Administration/BankExchange-Table",
  PMTM: "/Administration/Bank-Information-Table",

  CASHTRACKER: "/Reports/Cash-Tracker",
  SUD: "/Reports/Summary-USD-Dashboard",
  MER: "/Reports/Month-End-Report",
  OVRRECON: "/Reports/Overall-Reconciliation",
  COMMISSION: '/Reports/Commission',
  ABAS: "/Reports/Amounts-By-Allocation-Status",
  ABASAB: "/Reports/Amounts-By-Allocation-Status-Aging-Bucket",
  CTABD: "/Reports/Receivable-By-Entity",
  ABAB: "/Reports/Amounts-By-Aging-Bucket",
  ABL: "/Reports/Amounts-By-LOB",
  RWF: "/Reports/Write-off",
  RD: "/Reports/Refund-Report",
  ABCT: "/Reports/Amounts-By-Correction-Type",
  CT: "/Reports/Corrective-Transfers",
  CAR: "/Reports/Cross-Allocation-Report",
  CFI: "/Reports/CFI",

  CMA: "/Reports/Current-Month-Allocation-Non-SCM",
  MUCT: "/Reports/Correction-Types",

  PremiumBDX: "/BDX/PremiumBDX",
  Dashboard: "/Dashboard",
  TreasuryList:"/Treasury/Treasury",
  PayoutSummary:"/Treasury/PayoutSummary",
  ExceptionReport:"/BDX/ExceptionReport",

};

export default ROUTES;
