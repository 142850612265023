import { Routes, Route } from "react-router-dom";
import BankTransactionsConatiner from "./Components/bankTransactions/BankTransactionsConatiner";
import DataTableAllocation from "./Components/documents/bankStatementlistAllocation";
import CashAllocation from "./Components/CashAllocation/CashAllocation";
import Login from "./containers/login";
import ROUTES from "./utils/routes";
import PrivateRoutes from "./PrivateRoutes";
import ForgetPassword from "./containers/forgetPassword";
import UserList from "./containers/administration/userMaintainance/usersList";
import ManageUser from "./containers/administration/userMaintainance/manageUser";
import CashTracker from "./Components/CashTracker/CashTracker";
import EntityContainer from "./Components/EntityTableMaintainance/EntityContainer";
import LobContainer from "./Components/Lob/LobContainer";
import ScmContainer from "./Components/SCMPartners/ScmContainer";
import BindingAgreementContainer from "./Components/BindingAgreements/BindingAgreement";
import { WorkList } from "./Components/WorkList/WorkList";
import CorrectionTypeContainer from "./Components/CorrectionTableMaintaince/CorrectionTableContainer";
import AllocationStatusContainer from "./Components/AllocationStatus/AllocationStatus";
import BrokerContainer from "./Components/Broker/BrokerContainer";
import CashTransferContainer from "./Components/CashTransfer/CashTransferContainer";
import PolicyContainer from "./Components/Policy/PolicyContainer";
import CurrencyTableContainer from "./Components/CurrencyTableMaintainance/CurrencyTableContainer";
import BankExchangeRate from "./Components/BankExchangeRate/BankExchangeRate";

import CashTrackerDashboard from "./Components/Reports/CashTracker.js";
import SummaryUSDDashboard from "./Components/Reports/SummaryUSDDashboard.js";
import MonthEndReport from "./Components/Reports/MonthlyEndReport.js";
import OverallReconciliationReport from "./Components/Reports/OverallReconciliationReport.js";
import CommissionReport from "./Components/Reports/CommissionReport.js";
import ReceivableByEntity from "./Components/Reports/ReceivableByEntity";


import WriteOff from "./Components/Reports/WriteOff";
import PremiumTableContainer from "./Components/PremiumTable/PremiumTable";
import UploadFilesConatiner from "./Components/UploadFiles/UploadFilesContainer";
import Cfi from "./Components/Reports/Cfi.js";
// import CrossAllocation from './Components/CashAllocation/CrossAllocation.jsx';
// import Refund from './Components/CashAllocation/Refund.jsx';
import RefundReport from "./Components/Reports/Refund.js";
import CrossAllocationReport from "./Components/Reports/CrossAllocation";
import { BankReconciliation } from "./Components/BankReconciliation/BankReconciliation";
import { LockUnlockContainer } from "./Components/AdministrationLockUnlock/LockUnlockContainer";
import { StatementUpLoad } from "./Components/StatementUpload/StatementUpLoad.jsx";
import AmountsByAllocationStatusReport from "./Components/Reports/AmountsByAllocationStatus";
import AmountsByAllocationStatusAgingBucket from "./Components/Reports/AmountsByAllocationStatusAgingBucket";
import AmountsByCorrectionType from "./Components/Reports/AmountsByCorrectionType";
import AmountsByAgingBucket from "./Components/Reports/AmountsByAgingBucket";
import AmountsByLOB from "./Components/Reports/AmountsByLOB";
import CurrentMonthAllocation from "./Components/Reports/CurrentMonthAllocation.js";
import CorrectionTypes from "./Components/Reports/CorrectionTypes.js";
import ExceptionReport from "./Components/BDXPaymentUpdates/ExceptionReport.js";
import Dashboard from "./Components/Dashboard/Dashboard";
import CorrectiveTransfers from "./Components/Reports/CorrectiveTransfers.js";
import TreasuryList from "./Components/BDXPaymentUpdates/TreasuryList.js";
import PayoutSummary from "./Components/BDXPaymentUpdates/PayoutSummary.js";
import PremiumBDX from "./Components/BDXPaymentUpdates/PremiumBDX.js";

const MyRoutes = () => {
  return (
    <Routes>
      <Route exact path={ROUTES.HOME} element={<Login />} />
      <Route exact path={ROUTES.FORGETPASSWORD} element={<ForgetPassword />} />
      <Route element={<PrivateRoutes />}>
        <Route
          exact
          path={ROUTES.MUBST}
          element={<BankTransactionsConatiner />}
        />
        <Route exact path={ROUTES.MUUF} element={<UploadFilesConatiner />} />
        <Route exact path={ROUTES.MUSU} element={<StatementUpLoad />} />
        <Route exact path={ROUTES.MUABT} element={<DataTableAllocation />} />
        <Route exact path={ROUTES.MUCASHTRACKER} element={<CashTracker />} />
        <Route exact path={ROUTES.AUM} element={<UserList />} />
        <Route exact path={ROUTES.ALU} element={<LockUnlockContainer />} />
        <Route exact path={ROUTES.WLAI} element={<WorkList />} />
        <Route exact path={ROUTES.MUBR} element={<BankReconciliation />} />
        <Route exact path={ROUTES.AUMAU} element={<ManageUser type="add" />} />
        <Route exact path={ROUTES.AUMEU} element={<ManageUser type="edit" />} />
        <Route exact path={ROUTES.AUMVU} element={<ManageUser type="view" />} />
        <Route path="/cash-allocation" element={<CashAllocation />} />
        <Route exact path={ROUTES.AETM} element={<EntityContainer />} />
        <Route exact path={ROUTES.LOBETM} element={<LobContainer />} />
        <Route exact path={ROUTES.SCMTM} element={<ScmContainer />} />
        <Route
          exact
          path={ROUTES.BATM}
          element={<BindingAgreementContainer />}
        />
        <Route exact path={ROUTES.CTTM} element={<CorrectionTypeContainer />} />
        <Route
          exact
          path={ROUTES.ASTM}
          element={<AllocationStatusContainer />}
        />
        <Route exact path={ROUTES.BTM} element={<BrokerContainer />} />
        <Route exact path={ROUTES.CTM} element={<CashTransferContainer />} />
        <Route exact path={ROUTES.PTM} element={<PolicyContainer />} />
        <Route exact path={ROUTES.CYTM} element={<CurrencyTableContainer />} />
        <Route exact path={ROUTES.BETM} element={<BankExchangeRate />} />

        {/* // Reports start */}
        <Route
          exact
          path={ROUTES.CASHTRACKER}
          element={<CashTrackerDashboard />}
        />
        <Route exact path={ROUTES.SUD} element={<SummaryUSDDashboard />} />
        <Route exact path={ROUTES.MER} element={<MonthEndReport />} />
        <Route
          exact
          path={ROUTES.OVRRECON}
          element={<OverallReconciliationReport />}
        />
        <Route exact path={ROUTES.COMMISSION} element={<CommissionReport />} />
        <Route
          exact
          path={ROUTES.ABAS}
          element={<AmountsByAllocationStatusReport />}
        />
        <Route
          exact
          path={ROUTES.ABASAB}
          element={<AmountsByAllocationStatusAgingBucket />}
        />
        <Route exact path={ROUTES.CTABD} element={<ReceivableByEntity />} />
        <Route exact path={ROUTES.ABAB} element={<AmountsByAgingBucket />} />
        <Route exact path={ROUTES.ABL} element={<AmountsByLOB />} />
        <Route exact path={ROUTES.RWF} element={<WriteOff />} />
        <Route exact path={ROUTES.RD} element={<RefundReport />} />
        <Route exact path={ROUTES.ABCT} element={<AmountsByCorrectionType />} />
        <Route exact path={ROUTES.CT} element={<CorrectiveTransfers />} />
        <Route exact path={ROUTES.CAR} element={<CrossAllocationReport />} />
        <Route exact path={ROUTES.CFI} element={<Cfi />} />

        {/* // Reports End */}

        <Route exact path={ROUTES.MUCT} element={<CorrectionTypes />} />
        <Route exact path={ROUTES.PMTM} element={<PremiumTableContainer />} />
        <Route exact path={ROUTES.CMA} element={<CurrentMonthAllocation />} />

        <Route exact path={ROUTES.Dashboard} element={<Dashboard />} />

        <Route
          exact
          path={ROUTES.ExceptionReport}
          element={<ExceptionReport />}
        />

        <Route exact path={ROUTES.TreasuryList} element={<TreasuryList />} />
        <Route exact path={ROUTES.PayoutSummary} element={<PayoutSummary />} />
        <Route exact path={ROUTES.PremiumBDX} element={<PremiumBDX />} />
        <Route path={"*"} element={<></>} />
      </Route>
    </Routes>
  );
};

export default MyRoutes;
