//login
export const Login = '/users/login/'

export const Refresh = '/users/token/refresh/'

export const VerifyOtp = '/users/verify-otp/'
export const CheckUser = '/users/check-user/'
export const ForgotPassword = '/users/create-password/'

export const userManagementGet = 'users/users_api/user/'

export const ResetPassword = 'users/reset-password/'

//user Managmenet API's
export const GetUsers = 'users/users_api/user/'

export const Divisions = 'documents/entity/'


//BankTransactions
export const MutlipleAssignTxnUser = 'bankmanagement/multiple_assign_transactions_to_user/'

// This will give both Broker Branches and Broker Names
export const BrokerList = 'documents/get_broker_list/'

export const getBrokerBranchDetailsByBrokerBranchName = 'documents/get_broker_by_broker_branch_name/'

export const CurrencyList = 'documents/currency_details/'
export const BankList = 'documents/get_banks_list/'
export const GetDetailsByBank = 'documents/get_details_by_bank/?bank_name='
export const BankTransaction = 'bankmanagement/bank_transaction/'
export const UserAssignTxn = 'bankmanagement/user_assign_transactions/'
export const getBanlTransactionDetailsURL = 'bankmanagement/get_bankdetails_by_txn_id?txn_id='

//Work list Actions
export const workListTableData = 'bankmanagement/get_workflow_list_id/'

export const getCashAllocationWriteOffURL = 'bankmanagement/cash_allocation_writeoff/'
export const getCashAllocationRefundURL = 'bankmanagement/cash_allocation_refund/'
export const getCashAllocationCorrectiveTransferURL = 'bankmanagement/cash_allocation_corrective/'
export const getCashAllocationCorrectionTypeURL = 'documents/correction_type/'
export const getCashAllocationCrossAllocationURL = 'bankmanagement/cross_allocation/'
export const getCashAllocationCFIURL = 'bankmanagement/cash_allocation_cfi/'
export const getPaymentNoVarianceURL = 'payment/exception_file/'

//Workflow
export const workflowBankTransaction = 'bankmanagement/workflow_bank_transactions/'

export const getBankTxnWorkflowStatus = `bankmanagement/bank_transaction_workflow_status_check/?`;

//cash Tracker
export const getCashTracker = "bankmanagement/get_cash_tracker/"
export const getCashTrackerReport = "bankmanagement/export_cash_tracker_report/"

//Enity Table Maintainance
export const EntityTableMaintainance = 'documents/entity/'

//LOB Table Maintainance

export const LOBTableMaintainance = 'documents/lob/'

//SCM Table  Maintainance
export const SCMTableMaintainance = 'documents/scm_partners/'

//Binding Agreements
export const BindingAgreements = 'documents/binding_agreement/'

//Correction Type Table Maintainance
export const CorrectionTypeTableMaintainance = 'documents/correction_type/'

//allocation Status

export const AllocationStatus = 'documents/allocation_status/'

// Broker Table Maintainance

export const Broker = 'documents/broker_info/'

//cash transfer 

export const CashTransfer = 'documents/cash_transfer/'

//policy

export const Policy = '/documents/policy_type/'

//currency 

export const CurrencyTable = 'documents/currency_details/'

// bank exchange 

export const BankExchangeRateAPI = 'documents/exchange_rate/'

//Premium Table

export const PremiumTable = 'documents/bank_data/'

//Upload Files

export const UploadFile = "bankmanagement/bank_reconciliation/"


//Bank Reconciliation
export const getBankReconciliationTable = "bankmanagement/bank_reconciliation_accountno/"

//Upload Files 
export const getUpLoadedBankFiles = "bankmanagement/upload_bank_files/"

export const cashAllocationLockedUnlocked = "bankmanagement/cash_allocation_locked_unlocked/"

export const getCashAllocationLockedUnlocked = "bankmanagement/cash_allocation_locked_unlocked_history/"

// BDX Payment Updates
export const premiumBDX = "payment/premium_bdx_report/"
export const payment="payment/payment_file/"
export const paymentTreasury="payment/coversheet/"
export const payoutSummary="payment/payout_summary/"
export const exceptionReport="payment/exception_file/"
// export const ViewBDXPaymentSheet="payment/view_premium_bdx_sheet/"

export const getAllPaymentIds="payment/get_all_paymentid"

// Dashboard
export const DashboardHeader = "bankmanagement/dashboard/"
export const BankBalanceDashboardHeader = "bankmanagement/dashboard_bank_balance/"
export const BankNameHeader = "bankmanagement/get_bank_names/"
export const BankAccountHeader = "bankmanagement/get_bank_account_based_on_bank_name/"

//Policy File Upload //May 19 2024 by Aparajita
export const PolicyFileUpload="documents/policy_data_excel_import/"
