import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function DialogBox({
  title,
  body,
  confirmText,
  cancelText = "Cancel",
  isOpen,
  onCancel,
  onConfirm,
  isDisableConfirm = false,
}) {
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "0 20px 20px" }}>
        <Button
          onClick={onCancel}
          size="medium"
          color="secondary"
          style={{ padding: "15px 0px", borderRadius: "13px" }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          size="medium"
          disabled={isDisableConfirm}
          style={{ padding: "15px 0px", borderRadius: "13px" }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogBox;
