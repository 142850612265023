export const policyFileColumnNames = [
    "id",
    "Producing Entity",
    "Class of Business",
    "Year of Account",
    "Syndicate Binder",
    "Policy Line Ref",
    "Policy Status",
    "Policy Activity Status",
    "Inception Date",
    "Expiry Date",
    "Date Cancelled",
    "Cancellation Reason",
    "Transaction Status",
    "UMR Number",
    "3rd Party Capacity Deployed",
    "SCM Partner",
    "Signed Line Pct",
    "Broker Order Pct",
    "Signed Order Pct",
    "Broker Commision Pct",
    "Coverholder Commision Pct",
    "Broker Reference",
    "Underwriter",
    "MOP",
    "Broker",
    "Master Broker",
    "Insured",
    "Summary Currency",
    "Summary ROE",
    "Settlement Ccy",
    "Settlement ROE",
    "Gross Written Premium 100 (Sett)",
    "Net Written Premium 100 (Sett)",
    "True Net Written Premium 100 (Sett)",
    "Original Ccy",
    "Original ROE",
    "Gross Written Premium 100 (Orig)",
    "Gross Written Premium Agency Share (Orig)",
    "Gross Written Premium Syndicate Share (Orig)",
    "Gross Written Premium Non-Syndicate Share (Orig)",
    "Net Written Premium 100 (Orig)",
    "Net Written Premium Agency Share (Orig)",
    "Net Written Premium Syndicate Share (Orig)",
    "Net Written Premium Non-Syndicate Share (Orig)",
    "True Net Written Premium 100 (Orig)",
    "True Net Written Premium Syndicate Share (Orig)",
    "True Net Written Premium Agency Share (Orig)",
    "True Net Written Premium Non-Syndicate Share (Orig)",
    "Gross Written Premium Syndicate Share (USD)",
    "Gross Written Premium Agency Share (USD)",
    "Gross Written Premium 100 (USD)",
    "Net Written Premium 100 (USD)",
    "True Net Written Premium 100 (USD)",
    "PremiumBasis",
    "Instalment Nbr",
    "Installment Category",
    "Installment Due date",
    "Installment Ccy (Orig)",
    "Installment Agency Amount (Sett)",
    "Installment Agency Amount (USD)",
    "Installment Amount Syndicate Share (Orig)",
    "Installment Amount Syndicate Share (Sett)",
    "Installment Amount Syndicate Share (USD)",
    "Paid Amount (USD)",
    "Last Allocation Date",
    "Diff (USD)",
    "Overdue Days",
    "Overdue Category"
  ];
  